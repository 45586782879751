<template>
    <div class="modal fade lnd-guest-modal" tabindex="-1" id="gst_lnd_mdl" data-bs-backdrop="static"
        data-bs-keyboard="false">
        <canvas id="fireworks"></canvas>
        <div class="modal-dialog modal-lg sfc-modal guest-register-modal">
            <div class="modal-content">
                <div class="modal-body">

                    <h2><strong>Thank you for playing your 25 Guest spins.</strong> To continue playing for free and earning
                        coins, please tell us more about yourself.</h2>

                    <div class="form-fields row form-fields-bind">
                        <!-- <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="playername_guest"
                                    v-model="this.profileData.name" placeholder="Player Name"
                                    @input="this.inputErrorState('name')">
                                <label for="playername_guest">Player Name</label>
                                <div class="alert alert-danger" role="alert" v-if="this.errors.name"> {{
                                    this.errors.name[0] }}</div>
                            </div>
                        </div> -->
                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="emailaddy_guest" placeholder="Your Email"
                                    v-model="this.profileData.email" @input="this.inputErrorState('email')">
                                <label for="emailaddy_guest">Your Email</label>
                                <div class="alert alert-danger" role="alert" v-if="this.errors.email"> {{
                                    this.errors.email[0] }}</div>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-col">
                                <div class="form-floating mb-3">
                                    <v-select class="form-select" id="f_country" label="name" :options="this.countries"
                                        v-model="this.profileData.country_id" :reduce="country => country.id"
                                        @option:selected="getStates"></v-select>
                                    <label for="f_country">Country</label>
                                </div>
                                <div class="alert alert-danger" role="alert" v-if="this.errors.country_id">
                                    {{ this.errors.country_id[0] }}</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="form-col">
                                <div class="form-floating mb-3">
                                    <v-select class="form-select" :options="this.states" id="fstate" label="name"
                                        v-model="this.profileData.state_id" :reduce="state => state.id"
                                        @option:selected="this.inputErrorState('state_id')"></v-select>
                                    <label for="fstate">State</label>
                                </div>
                                <div class="alert alert-danger" role="alert" v-if="this.errors.state_id">
                                    {{ this.errors.state_id[0] }}</div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="form-floating mb-3">
                                <input type="password" v-model="this.profileData.password" class="form-control"
                                    id="passwordf" placeholder="Password" @input="this.inputErrorState('password')">
                                <label for="passwordf">Password</label>
                                <div class="alert alert-danger" role="alert" v-if="this.errors.password"> {{
                                    this.errors.password[0] }}</div>
                            </div>
                        </div>

                        <div class="form-check d-flex justify-content-center">
                            <input class="form-check-input me-2" v-model="this.profileData.age" id="email-age-terms"
                                type="checkbox" @change="this.inputErrorState('age')" checked>
                            <label class="form-check-label" for="email-age-terms">
                                I agree with terms and conditions
                            </label>
                        </div>
                        <div class="alert alert-danger" v-if="this.errors.age"> {{ this.errors.age[0] }} </div>
                        <div class="mt-3 text-center">
                            <button @click="bindUserAccount" class="sfn-btn lg"><span>Complete
                                    Account</span></button>
                        </div>
                    </div>
                    <p class="text-center mt-4" style="font-size: 13px;" > After you complete your account you will be redirected to verify your email.</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import axios from 'axios'
import UserService from '../api-services/user.service.js'
import vSelect from 'vue-select'
export default {
    name: 'guest-bind-notice',
    components: {
        vSelect
    },
    data() {
        return {
            countries: [],
            states: [],
            errors: [],
            profileData: {
                // name: null,
                email: null,
                country_id: null,
                state_id: null,
                password: null,
                age: false
            },
            other: {
                id: 'other',
                name: "Other",
                country_id: null,
                other: true,
            },
            defaultStateOption: {
                id: null,
                name: 'Choose State',
                country_id: null,
                disabled: true,
            }
        }
    },

    computed: {},

    methods: {
        logoutToEmail() {
            this.$store.dispatch('logoutGuestToEmail').then(() => {
                this.$isLoading(true)
                window.location.href = '/register-privacy'
                // this.$router.push('/register-privacy')
            }).catch((error) => {
                //
                this.dummyErrorHolder = error
                this.$isLoading(false)
            })

        },

        getAllCountries: function () {
            this.$isLoading(true)

            if (!this.countries.length) {
                UserService.getCountries()
                    .then((response) => {
                        this.countries = response.data.countries
                    })
                    .catch((error) => {
                        this.generalError = error
                        this.$isLoading(false)
                    })
                    .finally(() => {
                        this.$isLoading(false)
                    })
            } else {
                this.$isLoading(false)
            }
        },

        getStates: function () {
            this.inputErrorState('country_id')
            this.states = []
            this.profileData.state_id = null
            this.other.country_id = this.profileData.country_id
            // this.empty.country_id = this.profileData.country

            this.$isLoading(true)
            UserService.getStates(this.profileData.country_id)

                .then((response) => {
                    this.states = response.data.states
                    this.states.unshift(this.other)
                    this.states.unshift(this.defaultStateOption)

                    this.$isLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    this.$isLoading(false)
                })
        },

        bindUserAccount: function () {
            this.$isLoading(true)
            if (this.$cookies.get('ref')) {
                axios.defaults.headers.common['X-API-HASH-REFERER-COOKIE'] = this.$cookies.get('ref')
            }
            UserService.bindAccount(this.profileData)
                .then(() => {
                    if (axios.defaults.headers.common["X-API-HASH-REFERER-COOKIE"]) {
                        delete axios.defaults.headers.common["X-API-HASH-REFERER-COOKIE"]
                    }
                    localStorage.setItem('user_email', this.profileData.email)
                    localStorage.setItem('binded-account', 1)
                    this.$cookies.remove(process.env.VUE_APP_GUEST_USER_PREFIX)
                    if (this.$cookies.isKey('lnd_gst')) {
                        this.$cookies.remove('lnd_gst')
                    }
                    if (this.$cookies.isKey('spn_cnt')) {
                        this.$cookies.remove('spn_cnt')
                    }


                    this.logoutToEmail()


                }).catch((error) => {
                    // console.log(error)
                    this.$isLoading(false)
                    this.errors = error.data.error
                })
                // .finally(() => {
                //     this.$isLoading(false)
                // })
        },
    },

    created() { },

    unmounted() { },

    mounted() {
        this.getAllCountries()
    },
}


</script>
  
<style scoped lang="scss"></style>