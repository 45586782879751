import axios from 'axios'

// const AUTH_RESOURCE_NAME = '/auth'


export default {

  // authentication requests

  createUserByEmail (data) {
    return axios.post('/auth/register', data)
  },

  completeUserRegister (data) {
    return axios.post('/auth/register-completed', data)
  },

  loginAsGuest (data) {
    return axios.post('/auth/login-as-guest', data)
  },

  bindAccount(data){
    return axios.put('me/bind-account', data)
  },

  login (data) {
    return axios.post('/auth/login', data)
  },

  logout () {
    return axios.post('/auth/logout')
  },

  forgotPassword (data) {
    return axios.post('/forgot-password', data)
  },

  resetPassword(token, data) {
    return axios.post(`/reset-password/token/${token}`, data)
  },
  
  getCountries() {
    return axios.get('/get-countries')
  },


  // user data requests

  getAllAvatars () {
    return axios.get('/auth/avatars')
  },

  getUserData() {
    return axios.get('/auth/me')
  },

  getUserSettings() {
    return axios.get('/me/settings')
  },

  saveUserSettings(data) {
    return axios.put('/me/settings', data)
  },

  saveUserData (data) {
    return axios.put('/me/profile', data)
  },



  // active time rewards requests

  getFinalBonus () {
    return axios.get('/final-bonus')
  },

  getActiveTimeRewards() {
    return axios.get('/active-rewards')
  },

  claimActiveTimeRewards(data) {
    return axios.post('/claim-active-reward', data)
  },

  pingClaimableReward(){
    return axios.post('/ping-claim')
  },

  counterActiveUser() {
    return axios.post('/active-reward-timer')
  },



  // daily tasks requests

  
  getTasks() {
    return axios.get('/tasks')
  },

  claimTask(id) {
    return axios.get(`tasks/collect/${id}`)
  },

  // notifications

  getUserNotifications() {
    return axios.get('/announcement')
  },

  seeMoreNotifications(page) {
    return axios.get(`/announcement?page=${page}`)
  },

  setNotificationRead(id) {
    return axios.get(`/announcement/${id}/read`)
  },

  // events

  fetchAllEvents() {
    return axios.get('/all-events')
  },


  fetchFeaturedEvents() {
    return axios.get('/events')
  },

  fetchEventData(slug) {
    return axios.get(`/events/${slug}`)
  },

  collectEventReward(slug) {
    return axios.post(`/events/${slug}/collect`)
  },

  // products

  fetchProducts() {
    return axios.post('/products')
  },

  getSku(sku) {
    return axios.get(`/product/${sku}`)
  },

  purchaseProduct(data) {
    return axios.post('/payment/nmi/do-payment', data)
  },

  // vault

  fetchVaultProduct() {
    return axios.get('/vault')
  },

  purchaseVaultAmount() {
    return axios.post('/vault/collect/')
  },


  // transactions


  getTransactions() {
    return axios.get('/payment-history')
  },
  
  seeMoreTransactions(page) {
    return axios.get(`/payment-history?page=${page}`)
  },

  // emails
  
  
  resendEmail(email){
    return axios.post('/resend/verification-email', email)
  },

  verifyToken(token) {
    return axios.get(`/email-validation/token/${token}`)
  },

  getStates(country_id) {
    return axios.get(`/get-states?country_id=${country_id}`)
  },
  
  // zones
  
  getZoneAds(slug) {
    return axios.get(`/zone-ads/${slug}`)
  },


  // partners

  getPartners() {
    return axios.get('/get-partners')
  },

  
}
