<template>
    <div class="modal fade orientation-modal" data-bs-backdrop="static" id="orientationModal" tabindex="-1" role="dialog"
        aria-labelledby="orientationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h5 class="ttl text-center"> Change the device orientation to landscape for the best experience! </h5>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import { Modal } from "bootstrap";
export default {
    name: 'orientation-view',

    data() {
        return {
            portrait: false,
			orientationModal: null,
			windowWidth: window.innerWidth,
			time: 0
        }
    },

    watch: {
        $route() {
            this.handleOrientationChange()
            // window.addEventListener(
            //     "orientationchange",
            //     this.handleOrientationChange
            // );
        }
    },

    methods: {
        handleOrientationChange() {
			const orientation = window.screen.orientation.type
            const IOSorientation = window.orientation

            if(this.$route.name !== 'game-view') {

                if (this.windowWidth < 1224) {
                    if (orientation === "landscape-primary" || IOSorientation === 90) {
                        this.portrait = false
                        this.orientationModal.hide()
                    } else {
    
                        this.portrait = true
                        this.orientationModal.show()
                    }
                }
            }
		},
    },

    mounted() { 
		this.orientationModal = new Modal('#orientationModal');
		this.handleOrientationChange()
		window.addEventListener(
			"orientationchange",
			this.handleOrientationChange
		);
    },
}


</script>
  
<style scoped lang="scss">

</style>