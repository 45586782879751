import store from '../store/index'
import router from "../router/router";
import UserService from '../api-services/user.service'
// import 'particles.js'
const mixins = {

  data() {
    return {
      loadingCounter: 0
    };
  },

  computed: {
  },


  created: function() {

  },

  methods: {
    chatraBox: function (d, w, c) {
				w.ChatraID = "KcH4TKXCzr9G9wjzR";
				var s = d.createElement("script");
				w[c] = w[c] || function () {
					(w[c].q = w[c].q || []).push(arguments);
				};
				s.async = true;
				s.src = "https://call.chatra.io/chatra.js";
				if (d.head) d.head.appendChild(s);
		},

    formatBigInt: function(x) {
      // Nine Zeroes for Billions
      return Math.abs(Number(x)) >= 1.0e+9

      ? (Math.abs(Number(x)) / 1.0e+9).toFixed(2) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(x)) >= 1.0e+6

      ? (Math.abs(Number(x)) / 1.0e+6).toFixed(2) + "M"

      : parseInt(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
		},

    formatToComa: function (x) {
      return parseInt(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    incrementLoading: function () {
      this.loadingCounter++
    },

    decrementLoading: function () {
      this.loadingCounter--
    },

    getUserData: function () {
      UserService.getUserData()
          .then((response) => {
              store.dispatch('setUser', response.data.data)
              if (response.data.data.details.logged_in_today && response.data.data.daily_bonus.collected) {
                router.push('/dashboard')
              } else {
                  router.push('/daily-bonus')
              }
          })
      },

      setAnalyticsData: function (c_id, user_email, user_name) {
        this.$analytics.identify(c_id, {
            email: user_email,
            first_name: user_name,
        });
      },

      inputErrorState: function (input) {
        if(this.errors) {
          this.errors[input] = ''
        }
        if(this.localErrors) {
          this.localErrors[input] = ''
        }
        if(this.guestErrors) {
          this.guestErrors[input] = ''
        }
        if(this.local_errors) {
          this.local_errors[input] = ''
        }
    },

    // generateParticles: function(element) {
		// 	window.particlesJS(
		// 		element, {
		// 		'particles': {
		// 			'number': {
		// 				'value': 100
		// 			},
		// 			'color': {
		// 				'value': ['#ffffff', '#ffffff', '#ffffff']
		// 			},
		// 			'shape': {
		// 				'type': 'star'
		// 			},
		// 			'opacity': {
		// 				'value': 2,
		// 				'random': true,
		// 				'anim': {
		// 					'enable': true
		// 				}
		// 			},
		// 			'size': {
		// 				'value': 3,
		// 				'random': true,
		// 				'anim': {
		// 					'enable': false,
		// 				}
		// 			},
		// 			'line_linked': {
		// 				'enable': false
		// 			},
		// 			'move': {
		// 				'enable': true,
		// 				'speed': .5,
		// 				'direction': 'none',
		// 				'random': true,
		// 				'straight': false,
		// 				'out_mode': 'out'
		// 			}
		// 		},
		// 		'interactivity': {
		// 			'detect_on': 'canvas',
		// 			'events': {
		// 				'onhover': {
		// 					'enable': false
		// 				},
		// 				'onclick': {
		// 					'enable': false
		// 				},
		// 				'resize': true
		// 			},
		// 			'modes': {
		// 				'repulse': {
		// 					'distance': 100,
		// 					'duration': 0.4
		// 				}
		// 			}
		// 		},
		// 		'retina_detect': true
		// 	});
		// }
  },
}


export default mixins