<template>
	<div class="background-loading">
		<div class="box">
			<div class="b b1"></div>
			<div class="b b2"></div>
			<div class="b b3"></div>
			<div class="b b4"></div>
		</div>
	</div>
</template>  

<script>
export default {
	name: 'loading-screen',
	components: {
	}
}
</script>

<style lang="scss">
.background-loading {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* transform: translate(-50%, -50%); */
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background-color: rgba(0, 0, 0, .98);
	z-index: 1500;
}

.box {
	width: 200px;
	height: 200px;

	overflow: hidden;
}

.box .b {
	border-radius: 50%;
	border-left: 4px solid;
	border-right: 4px solid;
	border-top: 4px solid transparent !important;
	border-bottom: 4px solid transparent !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: ro 2s infinite;
}

.box .b1 {
	border-color: #d76ff2;
	width: 120px;
	height: 120px;
}

.box .b2 {
	border-color: #56b8f8;
	width: 100px;
	height: 100px;
	animation-delay: 0.2s;
}

.box .b3 {
	border-color: #2ECC71;
	width: 80px;
	height: 80px;
	animation-delay: 0.4s;
}

.box .b4 {
	border-color: #34495E;
	width: 60px;
	height: 60px;
	animation-delay: 0.6s;
}

@keyframes ro {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	50% {
		transform: translate(-50%, -50%) rotate(-180deg);
	}

	100% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
}
</style>