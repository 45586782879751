<template>
		<div class="modal fade inactivity-modal" data-bs-backdrop="static" id="inactivityModal" tabindex="-1" role="dialog"
			aria-labelledby="inactivityModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md" role="document">
				<div class="modal-content">
					<div class="modal-body text-center">
						<h5 class="ttl text-center"> You were inactive for too long. </h5>
						<h6 class="text-center"> You have one minute to resume your activity or you will be automatically logged out! </h6> 
						<button class="sfn-btn sfn-btn-pink lg" @click="this.clearIdle()" data-bs-dismiss="modal">
							<span> Resume </span> 
						</button>
					</div>
				</div>
			</div>
		</div>
</template>
  
  
<script>
import { Modal } from "bootstrap";
export default {
    name: 'inactivity-notice-view',

    data() {
        return {
            idle_timeout: 20,
            clearedinterval: false,
            inactivityModal: null,
            idleInterval: null,
            modalInterval: null,
            idleCounter: 0,
			wkl: undefined,
        }
    },

    methods: {
		releaseWakeState: async function () {
			this.wkl = await navigator.wakeLock.request();
			if(this.wkl) this.wkl.release()
			this.wkl = null
		},

        checkIdle: function () {
			var minutes = true; // change to false if you'd rather use seconds
			var interval = minutes ? 60000 : 1000;



			this.idleInterval = setInterval(() => {
				if (++this.idleCounter >= this.idle_timeout) {
					this.clearedinterval = false
					this.inactivityModal.show()

					if (this.clearedinterval == false) {
						this.modalInterval = setTimeout(() => {
							this.$store.dispatch('logoutRequest').then(() => {
								this.releaseWakeState()
								window.location.href = '/login'
							})
						}, 60000)
					}
				}
			}, interval);
		},


		clearIdle: function () {
			this.idleCounter = 0
			this.modalInterval = null
			this.clearedinterval = true
			clearTimeout(this.modalInterval)
		}
    },

    computed: {},

    updated() {},

    unmounted() { 
        document.removeEventListener('mousemove', this.clearIdle)
		document.removeEventListener('click', this.clearIdle)
		document.removeEventListener('keydown', this.clearIdle)
		document.removeEventListener('ontouchstart', this.clearIdle)
		document.removeEventListener('ontouchmove', this.clearIdle)
		document.removeEventListener('ontouchend', this.clearIdle)
		document.removeEventListener('ontouchcancel', this.clearIdle)

		clearInterval(this.idleInterval)
		clearTimeout(this.modalInterval)
		this.idleInterval = null
		this.modalInterval = null
    },

    mounted() { 
        if(this.$store.getters.user && !this.$store.getters.user.details.is_guest) {
            this.inactivityModal = new Modal('#inactivityModal')

			this.checkIdle()

			document.addEventListener('mousemove', this.clearIdle)
			document.addEventListener('click', this.clearIdle)
			document.addEventListener('keydown', this.clearIdle)
			document.addEventListener('ontouchstart', this.clearIdle)
			document.addEventListener('ontouchmove', this.clearIdle)
			document.addEventListener('ontouchend', this.clearIdle)
			document.addEventListener('ontouchcancel', this.clearIdle)
		}
    },

    created() {

    }
}


</script>
  
<style scoped lang="scss">

</style>